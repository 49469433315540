import fancybox from './ts/fancybox'
import sliderSwiper from './ts/slider-swiper'
import smoothScroll from './ts/smooth-scroll'
import scrollHeader from './ts/scroll-header'
import currentTab from './ts/current-tab'
import currentYear from './ts/current-year'
import scrollTo from './ts/scroll-to'
import theme from './ts/theme'
import animation from './ts/animation'
import waved from './ts/waved'
import submitHandler from './ts/submit-handler'
import inputs from './ts/inputs'
import phoneMask from './ts/phone-mask'
import preloader from './ts/preloader'

import '@fancyapps/ui/dist/fancybox/fancybox.css'
import 'swiper/css/bundle'
import './scss/main.scss'

window.addEventListener('DOMContentLoaded', ((): void => {
  fancybox()
  sliderSwiper()
  currentTab()
  currentYear()
  smoothScroll()
  scrollHeader()
  scrollTo()
  theme()
  animation()
  waved()
  submitHandler()
  inputs()
  phoneMask()
  preloader()
}) as EventListener)
