import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Grid,
  Thumbs,
} from 'swiper/modules'
import { media } from './utils'

export interface QuizSwiper extends Swiper {
  visibleSlides: [HTMLDivElement]
}

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

const createReviewsSlider = (): void => {
  const slider = document.querySelector(
    '*[data-slider="reviews"]'
  ) as HTMLDivElement

  if (!slider) return

  const value: string = slider.dataset.slider
  const swiper = slider.querySelector(
    `*[data-slider-swiper="${value}"]`
  ) as HTMLDivElement

  new window.Swiper(swiper, {
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 24,
    grabCursor: true,
    loop: true,
    watchSlidesProgress: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.5,
      },
      [media.md]: {
        slidesPerView: 2,
      },
      [media.lg]: {
        slidesPerView: 2.5,
      },
      [media.xl]: {
        slidesPerView: 3,
      },
    },
  }) as Swiper
}

export default (): void => {
  createReviewsSlider()
}
